<template>
  <div class="nav">
    <ul class="nav-list">
      <slot></slot>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'nav-list',

  inject: {
    handleNav: {}
  }
}
</script>
